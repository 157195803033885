<template>
    <section id="ToolSection" class="py-10 bg-gray-100 overflow-hidden">
        <div class="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 md:gap-8">
            <!-- First Tool Box with Vertical Line -->
            <div ref="vueBox" class="flex flex-col items-center space-y-4 md:border-r md:pr-8">
                <img src="../assets/vuelogo.png" class="h-16 object-contain lg:w-32" />
                <h1 class="font-bold text-3xl font-jakarta">Vue.js</h1>
                <p class="text-center px-4 md:px-0 font-jakarta">GenPulse gebruikt Vue.js om onze websites vanaf nul op te bouwen. Dit JavaScript-framework biedt flexibiliteit en zorgvuldige planning.</p>
            </div>
            
            <!-- Second Tool Box with Vertical Line -->
            <div ref="tailwindBox" class="flex flex-col items-center space-y-4 md:border-r md:pr-8 mt-8 md:mt-0">
                <img src="../assets/tailwindlogo.png" class="h-16 object-contain lg:w-32" />
                <h1 class="font-bold text-3xl font-jakarta">Tailwind CSS</h1>
                <p class="text-center px-4 md:px-0 font-jakarta">GenPulse heeft een licentie bij Tailwind, dat onderzoek doet naar kleurpaletten en layouts. Hierdoor kunnen we eenvoudig aantrekkelijke websites maken.</p>
            </div>
            
            <!-- Third Tool Box (No Vertical Line after Last Box) -->
            <div ref="hostingerBox" class="flex flex-col items-center space-y-4 mt-8 md:mt-0">
                <img src="../assets/hostingerlogo.png" class="h-16 object-contain lg:w-32" />
                <h1 class="font-bold text-3xl font-jakarta">Hostinger</h1>
                <p class="text-center px-4 md:px-0 font-jakarta">GenPulse host alle websites met Hostinger. Ze bieden SSL-certificaten, goede beveiliging, en gepersonaliseerde e-mailintegratie.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ToolSection',
    mounted() {
        // Observe each tool box for intersection
        this.observeToolBox(this.$refs.vueBox, 'animate-fly-in-left', 'animate-fly-out-left');
        this.observeToolBox(this.$refs.tailwindBox, 'animate-fade-in', 'animate-fade-out');
        this.observeToolBox(this.$refs.hostingerBox, 'animate-fly-in-right', 'animate-fly-out-right');
    },
    methods: {
        observeToolBox(element, animateInClass, animateOutClass) {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.01 // Adjust threshold as needed
            };

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        element.classList.add(animateInClass);
                        element.classList.remove(animateOutClass);
                    } else {
                        element.classList.remove(animateInClass);
                        element.classList.add(animateOutClass);
                    }
                });
            }, options);

            observer.observe(element);
        }
    }
};
</script>

<style scoped>
/* Animation keyframes */
@keyframes fly-in-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fly-in-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fly-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes fly-out-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Animation classes */
.animate-fly-in-left {
    animation: fly-in-left 1s ease-in-out forwards;
}

.animate-fly-in-right {
    animation: fly-in-right 1s ease-in-out forwards;
}

.animate-fly-out-left {
    animation: fly-out-left 1s ease-in-out forwards;
}

.animate-fly-out-right {
    animation: fly-out-right 1s ease-in-out forwards;
}

.animate-fade-in {
    animation: fade-in 1s ease-in-out forwards;
}

.animate-fade-out {
    animation: fade-out 1s ease-in-out forwards;
}

/* Responsive Styles */
@media (max-width: 1023px) {
    .md:border-r {
        border-right: none;
    }
    .md:pr-8 {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .max-w-6xl {
        max-width: 95%;
    }
}
</style>
