<template>
    <section id="FooterSection" class="bg-[#263555] py-4 md:py-6">
        <div class="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-0">
            <!-- Left Section with Text -->
            <div class="text-white flex flex-wrap items-center mb-4 md:mb-0">
                <span class="text-white">&copy;</span>
                <h1 class="ml-2 font-jakarta">GenPulse - Alle rechten voorbehouden.</h1>
                <h1 class="ml-4 font-jakarta">Kvk: 86957635</h1>
                <h1 class="ml-4 font-jakarta">Tel: +31624652833</h1>
            </div>
            <!-- Right Section with Icons -->
            <div class="flex items-center space-x-4">
                <!-- Email Icon -->
                <a href="mailto:DamianPlomp@hotmail.com" class="text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 48 48" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <path d="M41.92,25.3V20.53h0V6.5a1,1,0,0,0-1-1H14.55a1,1,0,0,0-1,1V14"/><path d="M18.19,22.19a3.68,3.68,0,0,0-3.89-3.68,3.83,3.83,0,0,0-3.49,3.89v3.41A3.69,3.69,0,0,0,14.5,29.5h0a3.69,3.69,0,0,0,3.69-3.69V22.19"/><path d="M4.5,16V32a2,2,0,0,0,2,2h16a2,2,0,0,0,2-2V16a2,2,0,0,0-2-2H6.5A2,2,0,0,0,4.5,16Z"/><line x1="43.5" y1="42.5" x2="24.5" y2="31.82"/><polyline points="11.67 34 11.67 42.5 43.5 42.5 43.5 24.39 27.59 33.56"/>
                    </svg>
                </a>
                <!-- LinkedIn Icon -->
                <a href="https://www.linkedin.com/in/damian-plomp-415835303/" target="_blank" rel="noopener noreferrer" class="text-white">
                    <svg fill="#ffffff" class="h-6 w-6 md:h-8 md:w-8" viewBox="0 0 310 310">
                        <path d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73 C77.16,101.969,74.922,99.73,72.16,99.73z"/>
                        <path d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4 c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"/>
                        <path d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599 c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319 c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995 C310,145.43,300.549,94.761,230.454,94.761z"/>
                    </svg>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FooterSection',
}
</script>
