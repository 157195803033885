<template>
    <section id="AboutSection">
        <div class="bg-gray-100">
            <div class="bg-gradient-to-r from-red-400 to-yellow-200 min-h-screen relative overflow-hidden rounded-bl-[7rem]">
                <div class="flex flex-col justify-center pb-8">
                    <h1 class="text-center text-white font-bold text-4xl md:text-8xl mt-10 font-jakarta">Een Brede Expertise</h1>
                    <h2 class="text-center mt-4 text-white font-[500] text-2xl md:text-4xl">
                        GenPulse is gespecialiseerd in website design &<br class="hidden md:block">development en consultancy
                    </h2>
                </div>
                <div class="line"></div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 mb-16 md:mb-0"> <!-- Increased bottom margin on smaller screens -->
                    <div class="flex justify-center">
                        <div
                            ref="developmentCard"
                            :class="['bg-[#263555]', 'h-[18rem]', 'w-[90%]', 'md:w-[40rem]', 'p-4', 'transform', 'transition-all', 'duration-1000', animationClassDev, 'shadow-lg', ' shadow-slate-950']"
                        >
                            <h1 class="text-white text-2xl md:text-4xl font-bold font-jakarta">Website Development & Design</h1>
                            <h2 class="text-white font-jakarta">GenPulse is gespecialiseerd in het ontwerpen en het devolopen van websites. Alles is mogelijk: van een webshop, tot een blogging website
                                of een simpele informatieve website.
                            </h2>
                        </div>
                    </div>
                    <div class="flex justify-center mt-8 md:mt-0"> <!-- Added top margin on smaller screens to separate cards vertically -->
                        <div
                            ref="consultancyCard"
                            :class="['bg-[#263555]', 'h-[18rem]', 'w-[90%]', 'md:w-[40rem]', 'p-4', 'transform', 'transition-all', 'duration-1000', animationClassConsult, 'shadow-lg', 'shadow-slate-950']"
                        >
                            <h1 class="text-white text-2xl md:text-4xl font-bold font-jakarta">Website Consultancy</h1>
                            <h2 class="text-white font-jakarta">
                                Heeft u al een website, of weet u nog niet precies wat u wilt? Geen probleem!
                                Naast het aanbieden van nieuwe websites kunnen we ook uw bestaande website 
                                verbeteren of u helpen bij het maken van de juiste keuzes.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutSection',
    data() {
        return {
            animationClassDev: '',
            animationClassConsult: '',
        };
    },
    methods: {
        handleIntersection(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (entry.target === this.$refs.developmentCard) {
                        this.animationClassDev = 'animate-fly-in-left';
                    } else if (entry.target === this.$refs.consultancyCard) {
                        this.animationClassConsult = 'animate-fly-in-right';
                    }
                } else {
                    if (entry.target === this.$refs.developmentCard) {
                        this.animationClassDev = 'animate-fly-out-left';
                    } else if (entry.target === this.$refs.consultancyCard) {
                        this.animationClassConsult = 'animate-fly-out-right';
                    }
                }
            });
        },
        setupIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.01
            };

            this.observer = new IntersectionObserver(this.handleIntersection, options);
            this.observer.observe(this.$refs.developmentCard);
            this.observer.observe(this.$refs.consultancyCard);
        }
    },
    mounted() {
        this.setupIntersectionObserver();
    },
    beforeUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }
};
</script>

<style scoped>
/* Responsive styles */
.min-h-screen {
    min-height: 90vh;
}

.line {
    position: absolute;
    bottom: 10;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 50%;
    background-color: white;
}

/* Horizontal line for small screens */
@media (max-width: 767px) {
    .line {
        bottom: auto;
        top: 62%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 4px;
    }
}

@media (max-width: 401px) {
    .line {
        bottom: auto;
        top: 59.5%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 4px;
    }
}

@media (max-width: 301px) {
    .line {
        bottom: auto;
        top: 67.5%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 4px;
    }
}


/* Animation keyframes */
@keyframes fly-in-left {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fly-in-right {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fly-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
    }
}

@keyframes fly-out-right {
    0% {
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

/* Animation classes */
.animate-fly-in-left {
    animation: fly-in-left 1s ease-in-out forwards;
}

.animate-fly-in-right {
    animation: fly-in-right 1s ease-in-out forwards;
}

.animate-fly-out-left {
    animation: fly-out-left 1s ease-in-out forwards;
}

.animate-fly-out-right {
    animation: fly-out-right 1s ease-in-out forwards;
}
</style>
