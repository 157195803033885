<template>
    <section id="ContactSection" class="min-h-screen py-12">
        <div class="max-w-4xl mx-auto text-center">
            <h1 class="text-4xl md:text-5xl font-bold mt-8 text-gray-800">Openheid en Samenwerking</h1>
            <p class="mt-4 text-lg md:text-xl text-gray-700 font-jakarta leading-relaxed">
                Een nauwe samenwerking en open communicatie vormen de kern van ons bedrijf. Eerlijkheid 
                is voor ons van essentieel belang en we bieden alleen aan wat we volledig ondersteunen.
                <br><br>
                GenPulse is een startend, maar gemotiveerd bedrijf dat
                gedreven is om het best mogelijke product 
                neer te zetten voor onze klanten. Onze passie voor 
                innovatie en klanttevredenheid staat centraal in alles wat we doen.
            </p>
        </div>
        <div class="max-w-lg mx-auto mt-12 bg-gray-200 shadow-lg rounded-lg p-8">
            <h2 class="text-lg md:text-xl mb-4 text-center text-gray-800 font-bold font-jakarta">Bent u benieuwd naar de mogelijkheden? Neem dan contact met ons op!</h2>
            <div class="flex flex-col md:flex-row items-center justify-center">
                <input class="border border-gray-300 rounded-l py-3 px-4 text-gray-800 mb-4 md:mb-0 md:mr-2 w-full md:w-auto focus:outline-none focus:border-gray-500" v-model="email" :placeholder="emailPlaceholder" />
                <button class="bg-[#111827] text-white py-3 px-6 rounded font-jakarta hover:bg-gray-800 transition-colors duration-300 focus:outline-none" @click="sendEmail">Verstuur</button>
            </div>
            <p class="mt-4 text-center text-gray-600">{{ emailMessage }}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ContactSection',
    data() {
        return {
            email: '',
            emailPlaceholder: 'Uw Email',
            emailMessage: ''
        };
    },
    methods: {
        sendEmail() {
            // Basic email validation
            if (!this.email || !this.isValidEmail(this.email)) {
                this.emailMessage = 'Vul een geldig e-mailadres in.';
                return;
            }

            // Send email using fetch
            fetch('contact-form/sendEmail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `email=${encodeURIComponent(this.email)}`
            })
            .then(response => {
                if (response.ok) {
                    this.emailMessage = 'Email verzonden! We nemen zo spoedig mogelijk contact met u op.';
                    this.email = ''; // Clear the input after successful send
                } else {
                    throw new Error('Email verzenden mislukt.');
                }
            })
            .catch(error => {
                console.error(error);
                this.emailMessage = 'Email verzenden mislukt. Probeer het later opnieuw.';
            });
        },
        isValidEmail(email) {
            // Basic email validation regex
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        }
    }
}
</script>

<style scoped>
/* Add your component-specific styles here if needed */
</style>
