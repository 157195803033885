<template>
  <HeroSection />
  <AboutSection />
  <!-- <BlogSection /> -->
  <ToolSection />
  <ContactSection />
  <FooterSection />
</template>

<script>
import AboutSection from './components/AboutSection.vue'
import HeroSection from './components/HeroSection.vue'
import ContactSection from './components/ContactSection.vue'
import FooterSection from './components/FooterSection.vue'
import ToolSection from './components/ToolSection.vue'
// import BlogSection from './components/BlogSection.vue'


export default {
  name: 'App',
  components: {
    HeroSection,
    AboutSection,
    // BlogSection,
    ToolSection,
    ContactSection,
    FooterSection
  }
}
</script>

<style>

</style>
