<template>
    <section id="HeroSection" class="h-screen md:h-[60rem] flex items-center justify-center">
        <div class="text-center px-4">
            <div>
                <img src="../assets/logo-removebg-preview.png" class="mx-auto mb-4 w-64 md:w-[52rem] animate-bounce-in" alt="Logo"/>
                <h2 :key="currentIndex" class="text-lg md:text-2xl relative animated-text font-jakarta" :class="{ 'fade-out': fadingOut, 'fade-in': !fadingOut }">{{ currentText }}</h2>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HeroSection',
    data() {
        return {
            phrases: [
                'De beste optie om uw start-up bedrijf op het internet zichtbaar te krijgen.',
                'De beste optie om uw website aantrekkelijk te maken voor anderen.',
                'De beste optie om hulp te krijgen bij het designen van uw website.'
                // Add more phrases as needed
            ],
            currentIndex: 0,
            animationDuration: 500, // Animation duration in milliseconds
            fadingOut: false, // Flag to control fading out animation
        };
    },
    computed: {
        currentText() {
            return this.phrases[this.currentIndex];
        }
    },
    methods: {
        playAnimation() {
            this.fadingOut = true; // Start fading out
            setTimeout(() => {
                this.currentIndex = (this.currentIndex + 1) % this.phrases.length;
                this.fadingOut = false; // Reset fading out flag
            }, this.animationDuration); // Wait for fade-out animation to complete
        },
        cyclePhrases() {
            setInterval(() => {
                this.playAnimation();
            }, 5000); // 5000 milliseconds = 5 seconds
        },
    },
    mounted() {
        this.cyclePhrases();
    },
};
</script>

<style scoped>
.animated-text {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-out {
    opacity: 0;
}

.fade-in {
    opacity: 1;
}

/* Keyframes for bouncing animation */
@keyframes bounce-in {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }
    70% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

/* Class for bouncing animation */
.animate-bounce-in {
    animation: bounce-in 2s ease-out;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .h-[60rem] {
        height: auto; /* Adjust height for smaller screens */
    }
    .w-[52rem] {
        width: 80%; /* Adjust width for smaller screens */
    }
}
</style>
